@import "src/scss/module";

.buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  flex-direction: column !important;
  gap: 0.25rem !important;
  z-index: #{$zindex-fixed};

  @include media-breakpoint-down(sm) {
    flex-direction: row !important;

    [role="button"] {
      span {
        display: none;
      }

      i {
        margin-left: 0 !important;
      }
    }
  }
}
